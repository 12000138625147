import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Intro from "../components/intro"
import Features3Grid from "../components/features-3-grid"
import Card from "../components/card"
import Carousel from "../components/carousel"
import Features4Grid from "../components/features-4-grid"

export const query = graphql`
  query {
    allFile(filter: {extension: {eq: "md"}, name: {eq: "home"}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              seo {
                title
                description
              }
              hero {
                image
                text
                title
                buttons {
                  text
                  link
                }
              }
              intro {
                text
                title
              }
              gridCols3 {
                image
                text
                button {
                  text
                  link
                }
              }
              cards {
                image
                title
                text
                button {
                  text
                  link
                }
              }
              carousel {
                text
                title
                images {
                  image
                }
              }
              equipmentIntro {
                text
                title
              }
              gridCols4 {
                image
                subtitle
                title
                text
              }
            }
          }
        }
      }
    }
  }
`

export default function Home(props) {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout>
      <Helmet>
          <html lang="de" />
          <meta charSet="utf-8" />
          <title>{data.seo.title}</title>
          <meta name="description" content={data.seo.description} />
          <meta name="robots" content="index, follow" />
          <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
      </Helmet>
      <Hero image={data.hero.image} title={data.hero.title} text={data.hero.text} buttons={data.hero.buttons} />
      <Intro title={data.intro.title} titleHeading="h2" text={data.intro.text} />
      <Features3Grid gridCols3={data.gridCols3} />
      <Card cards={data.cards} />
      <Carousel title={data.carousel.title} text={data.carousel.text} images={data.carousel.images} />
      <Intro title={data.equipmentIntro.title} titleHeading="h4" text={data.equipmentIntro.text} />
      <Features4Grid gridCols4={data.gridCols4} gridColsMobile="1" colClass="break-words hyphens-auto" />
    </Layout>
  )
}
