import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function Intro(props) {
  return (
    <div className="mt-16 sm:mt-24 lg:mt-32">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        {props.subtitle && <h2 className="block text-base text-petrol font-semibold mb-4">{props.subtitle}</h2>}
        {props.title && <props.titleHeading className="block text-4xl !leading-[1.1] font-serif text-petrol sm:text-5xl lg:text-6xl break-words hyphens-auto">{props.title}</props.titleHeading>}
        {props.text && <p className="mt-6 max-w-3xl mx-auto text-base md:text-lg leading-normal text-petrol text-center">{props.text}</p>}
        {props.button && <div className="mt-8">
          <AniLink
            paintDrip
            to={props.button.link}
            duration={1}
            hex="F5DCAD"
            className="inline-flex px-8 py-3 md:px-10 md:py-4 border border-transparent text-base font-medium rounded-full text-white bg-petrol hover:bg-green whitespace-nowrap transition-colors"
          >
            {props.button.text}
          </AniLink>
        </div>}
      </div>
    </div>
  )
}