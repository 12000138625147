import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "../components/image"

export default function Hero(props) {
  return (
    <div className="bg-green-light lg:relative overflow-hidden">
      <div className="mx-auto max-w-7xl w-full py-16 sm:py-24 lg:py-32 text-center lg:text-left">
        <div className="px-4 lg:w-3/5 sm:px-6 z-[1] relative">
          {props.title && <h1 className="text-3xl !leading-[1.1] font-serif text-white sm:text-5xl lg:text-6xl md:max-w-4xl mx-auto lg:mx-0">{props.title}</h1>}
          {props.text && <p className="mt-6 mx-auto text-white text-base md:text-lg max-w-xl lg:mx-0">{props.text}</p>}
          {props.buttons && <div className="mt-10 flex gap-3 flex-wrap justify-center lg:justify-start">
            {props.buttons.map((button, i) => {
              return (
                <div key={i} className="rounded-full">
                  <AniLink
                    paintDrip
                    to={button.link}
                    duration={1}
                    hex="F5DCAD"
                    className={i === 0 ? "w-full flex items-center justify-center px-8 py-3 md:px-10 md:py-4 border border-transparent text-base font-semibold rounded-full text-white bg-petrol hover:bg-green whitespace-nowrap transition-colors" : "w-full flex items-center justify-center px-8 py-3 md:px-10 md:py-4 border border-transparent text-base font-semibold rounded-full text-petrol bg-white hover:bg-green hover:text-white whitespace-nowrap transition-colors"}
                  >{button.text}</AniLink>
                </div>
              )
            })}
          </div>}
        </div>
      </div>
      {props.image && <div className="relative w-full h-72 sm:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-auto mb-8 sm:mb-12 lg:my-32">
        <Image
          filename={props.image.split('/assets/').pop()}
          objectFit="contain"
          objectPosition="center center"
          alt={props.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'),'')}
          className="absolute inset-0 w-full h-full object-contain"
        />
      </div>}
    </div>
  )
}
