import React from "react"
import Image from "../components/image"
import { Swiper, SwiperSlide } from 'swiper/react'

import "swiper/css/bundle"

import SwiperCore, { Autoplay, Pagination, EffectFade } from "swiper/core"
SwiperCore.use([Autoplay, Pagination, EffectFade])

export default function Carousel(props) {
  return (
    <div className="relative bg-yellow overflow-hidden py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          {props.title && <h4 className="max-w-4xl mx-auto text-4xl !leading-[1.1] font-serif text-white text-center sm:text-5xl lg:text-6xl">
            {props.title}
          </h4>}
          {props.text && <p className="mt-6 max-w-3xl mx-auto text-base md:text-lg leading-normal text-white text-center">
            {props.text}
          </p>}
        </div>
        {props.images && <div className="mt-8 sm:mt-12 lg:mt-16">
          <Swiper
            effect={"fade"}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            pagination={{ clickable: true }}
          >
            {props.images.map((item, i) => {
              return (
                <SwiperSlide key={i} className="aspect-w-16 aspect-h-9 sm:aspect-h-[auto] sm:aspect-w-[auto] overflow-hidden">
                  <Image filename={item.image.split('/assets/').pop()} alt={item.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'),'')} className="!absolute sm:!relative" />
                </SwiperSlide>
                // <SwiperSlide key={i} className="aspect-w-3 aspect-h-2 overflow-hidden">
                //   <Image filename={item.image.split('/assets/').pop()} alt={item.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'),'')} className="!absolute" />
                // </SwiperSlide>
              )
            })}
          </Swiper>
        </div>}
      </div>
    </div>
  )
}
