import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "../components/image"

export default function Card(props) {
  return (
    <div>
      {props.cards.map((card, i) => {
        return (
          <div key={i} id={card.title.replace(/\s+/g, '-').toLowerCase()} className="relative my-16 sm:my-24 lg:my-32">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className={i % 2 === 0 ? "mb-12 sm:mb-16 lg:mb-0 lg:col-start-2" : "mb-12 sm:mb-16 lg:mb-0 lg:col-start-1"}>
                <div className="lg:relative lg:h-full">
                  <Image
                    filename={card.image.split('/assets/').pop()}
                    alt={card.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'), '')}
                    objectFit="cover"
                    objectPosition="center center"
                    className={i % 2 === 0 ? "w-full lg:!absolute lg:left-0 lg:h-full lg:w-[50vw] lg:max-w-none" : "w-full lg:!absolute lg:right-0 lg:h-full lg:w-[50vw] lg:max-w-none"}
                  />
                </div>
              </div>
              <div className={i % 2 === 0 ? "px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-1" : "px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2"}>
                <div className="mt-6">
                  <h3 className="text-4xl !leading-[1.1] font-serif text-petrol sm:text-5xl lg:text-6xl">
                    {card.title}
                  </h3>
                  {card.text && <p className="mt-6 text-base md:text-lg text-petrol">
                    {card.text}
                  </p>}
                  {card.button.text && <div className="mt-8">
                    <AniLink
                      paintDrip
                      to={card.button.link}
                      duration={1}
                      hex="F5DCAD"
                      className="inline-flex px-8 py-3 md:px-10 md:py-4 border border-transparent text-base font-medium rounded-full text-white bg-petrol hover:bg-green whitespace-nowrap transition-colors"
                    >
                      {card.button.text}
                    </AniLink>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
