import React from "react"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "../components/image"
import showdown from "showdown"

import {
  ChevronRightIcon
} from "@heroicons/react/solid"

export default function Features3Grid(props) {
  const converter = new showdown.Converter({ 'simpleLineBreaks': true })
  
  return (
    <div className="mt-8 mb-16 sm:mt-12 sm:mb-24 lg:mt-16 lg:mb-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {props.gridCols3.map((item, i) => {
            return (
              <div key={i}>
                {item.image && <Image
                  filename={item.image.split('/assets/').pop()}
                  alt={item.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'), '')}
                  objectFit="contain"
                  objectPosition="center center"
                  className=""
                />}
                <div className="mt-4 text-base text-petrol" >
                  {item.text && <div dangerouslySetInnerHTML={{__html: converter.makeHtml(item.text)}}></div>}

                  {item.button.text && !item.button.link.includes("#") && <AniLink
                  paintDrip
                  to={item.button.link}
                  duration={1}
                  hex="F5DCAD"
                  className="mt-2 text-base font-semibold text-petrol flex justify-center items-center"
                  >
                    {item.button.text}
                    <ChevronRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </AniLink>}

                  {item.button.text && item.button.link.includes("#") && <Link
                  to={item.button.link}
                  className="mt-2 text-base font-semibold text-petrol flex justify-center items-center"
                  >
                    {item.button.text}
                    <ChevronRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Link>}
                  
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
